@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.space-bg {
  background-image: url('../public/imgs/space-bg-1.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.tinted {
  background-color: rgba(0, 0, 0, .4);
}

.alien-green {
  color: #00FF5D;
}

.bg-alien-green {
  background-color: #00FF5D;
}

.bg-dg {
  background-color: #212434;
}

.bg-alien-green:hover {
  transition-duration: 200ms;
  background-color: transparent;
  border: 1px solid #00FF5D;
  color: #00FF5D;
}

.bg-grey {
  background-color: #939692;
}

.bg-grey:hover {
  background-color: transparent;
  border: 1px solid #939692;
  color: #939692;
}

.bg-dark {
  background-image: linear-gradient(#000000, #000525);
}

.info-txt {
  font-family: 'Source Code Pro', monospace;
}

.headings {
  font-family: 'Rubik', sans-serif;
}

.smol-spacer {
  height: 5px;
  width: 15%;
}

.lg-spacer {
  height: 2px;
  width: 90%;
}

.squiggle-square {
  background-color: white;
  border-radius: 0px 50px 0px 50px
}


/* Auth Css */
.regForm {
  color: white;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.successmsg {
  background-color: rgb(151, 255, 137);
  color: rgb(34, 84, 26);
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.instructions > svg {
  color: white;
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.persistCheck {
  font-size: 0.75rem;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck label {
  margin: 0;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 5px 2px 2px;
}
.card {
  cursor: pointer;
}

.card:hover > div > .card-heading {
  text-decoration: underline;
  color: #00FF5D;
}

.tutorial:hover {
  color: #00FF5D;
  text-decoration: underline;
}

/* The Modal (background) */
.modal {
 /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}


/*scrollbar styling */

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar {
  background: none;
}

.scrollbar::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: white;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}


.modal-scroll::-webkit-scrollbar {
  width: 4px;
}

.modal-scroll::-webkit-scrollbar {
  background: none;
}

.modal-scroll::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: white;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.icon-button:hover {
  color: #00FF5D;
}

.dash-title:hover {
  color: #00FF5D;
}

/*filter styles*/

.filter-nav {
  width : 300px; 
  margin-left: 7%;
}

.filter-span {
  padding : 20px;
  color : white;
  font-size : 1.2em;
  cursor : pointer;
  display: block;
}

.filter-span::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide {
  clear:both;
  width:100%;
  height:0px;
  overflow: auto;
  transition: height .4s ease;
}

.slide::-webkit-scrollbar {
  width: 2px;
}

.slide::-webkit-scrollbar-thumb {
  background-color: white;
}

.slide li {padding : 20px;}

.slide li:hover {
  text-decoration: underline;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor : pointer;
}

#touch {position: absolute; opacity: 0; height: 0px;}    

#touch:checked + .slide {
  height: 300px;
} 

/*mini nav */
.filter-nav-1 {
  width : 300px; 
}

.filter-span-1 {
  padding : 20px;
  color : white;
  font-size : 1.2em;
  cursor : pointer;
  display: block;
}

.filter-span-1::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide-1 {
  clear:both;
  width:33.7%;
  height:0px;
  overflow: auto;
  transition: height .4s ease;
  position: absolute;
  z-index: 40;
}

.slide-1::-webkit-scrollbar {
  width: 2px;
}

.slide-1::-webkit-scrollbar-thumb {
  background-color: white;
}

.slide-1 li {padding : 20px;}

.slide-1 li:hover {
  text-decoration: underline;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor : pointer;
}

#touch-1 {position: absolute; opacity: 0; height: 0px;}    

#touch-1:checked + .slide-1 {
  height: 300px;
  z-index: 40;
} 